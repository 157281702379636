import {
  Box,
  Button,
  FormField,
  Grid,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';
import { State } from '@hookstate/core';
import React, { useEffect, useRef, useState } from 'react';
import { ForceAwakensStateInterface } from 'src/stores/app';
import * as APIt from '../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { URLS, UserActionNames } from 'src/constants/Constants';

export interface ISelectSiteProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
  refreshUserSitesCallback: Function;
  siteSelectedCallback: Function;
  userSites: APIt.Site[];
  userSitesLoading: boolean;
}

export default function SelectSite(props: ISelectSiteProps ) {

  const [userSiteSegmentNameValue, setUserSiteSegmentNameValue] = useState<string | undefined>(props.forceAwakensState.get().selectedSite?.SegmentName);
  const [userSiteSegmentLocationValue, setUserSiteSegmentLocationValue] = useState<string | undefined>(props.forceAwakensState.get().selectedSite?.SegmentLocation);
  const [userSiteSegmentSourceValue, setUserSiteSegmentSourceValue] = useState<string | undefined>(props.forceAwakensState.get().selectedSite?.SegmentSource);
  const [siteRequiredAlertVisible, setSiteRequiredAlertVisible] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.common.SelectSite');

  const siteInputRef = useRef<SelectProps.Ref>(null);

  const selectSiteHandler = async () => {
    createUserAction(
      {
        actionName: UserActionNames.SelectSite,
        username: props.forceAwakensState.username.value,
        parameters: JSON.stringify(
          {
            selectedSite: userSiteSegmentNameValue,
          })
      });
      
    if (!userSiteSegmentLocationValue || !userSiteSegmentNameValue || !userSiteSegmentSourceValue) {
      setSiteRequiredAlertVisible(true);
      return;
    }
    const selectedSite = {
      SegmentLocation: userSiteSegmentLocationValue,
      SegmentName: userSiteSegmentNameValue,
      SegmentSource: userSiteSegmentSourceValue,
      siteCode: userSiteSegmentNameValue.split('-')[userSiteSegmentNameValue.split('-').length-1]
    };
    props.forceAwakensState.selectedSite.set(selectedSite);
    setSiteRequiredAlertVisible(false);
    localStorage.setItem('lastSelectedSite', JSON.stringify(selectedSite));
    props.siteSelectedCallback();
  };

  const EOF = '/EOF/';

  const siteFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setUserSiteSegmentNameValue(detail.selectedOption.label);
    setUserSiteSegmentLocationValue(detail.selectedOption.value?.split(EOF)[1]);
    setUserSiteSegmentSourceValue(detail.selectedOption.value?.split(EOF)[2]);
  };

  const stage = 'beta'

  useEffect(() => {
    if (siteInputRef) siteInputRef.current?.focus();
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              disabled={userSiteSegmentNameValue == undefined}
              onClick={selectSiteHandler}
              variant='primary'
            >
              {bundle.getMessage('select')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={() => setSiteRequiredAlertVisible(true)}
      size='small'
      visible
      header={bundle.getMessage('site') + ((stage.toLowerCase() !== 'prod') ? ' - ' + stage.toUpperCase() : '')}
    >
        <SpaceBetween size='xs' direction='vertical'>
          <Grid gridDefinition={[{colspan: 9},{colspan: 1}]}>
            <FormField label={bundle.getMessage('site')}>
              <Select
                filteringType='auto'
                loadingText='Loading Your Sites'
                onChange={({ detail }) => siteFieldOnChangeHandler(detail)}
                options={props.userSites.map(el => {
                  return (
                    {
                      label: el.SiteCode,
                      value: `${el.SiteCode.toString()}${EOF}${el.SegmentLocation}${EOF}${el.SegmentSource}`
                    });
                }).sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                })}
                ref={siteInputRef}
                selectedAriaLabel='Selected'
                selectedOption={{ label: userSiteSegmentNameValue, value: userSiteSegmentNameValue?.toString() }}
                statusType={props.userSitesLoading ? 'loading' : 'finished'}
                virtualScroll={props.userSites.length > 500}
              />
              <a href= {URLS.SiteNotFound} target='_blank' rel='noopener'>Not seeing the sites you expect?</a>
            </FormField>
            <FormField label='&#8203;'>
              <Button
                ariaLabel='refresh sites'
                iconName='refresh'
                loading={props.userSitesLoading}
                onClick={() => props.refreshUserSitesCallback()}
                variant='normal'
              />
            </FormField>
          </Grid>
        </SpaceBetween>
    </Modal>);
}